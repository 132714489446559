import React from "react"
import clsx from "clsx"
import Container from "@ecom/ui/components/Container"
import * as styles from "./promotion.module.scss"
import img1 from "./img/Img_3000_1/img1.svg"
import img2 from "./img/Img_3000_1/img2.svg"
import img3 from "./img/Img_3000_1/img3.svg"
import { getDatesFromQuery } from "../../../utils/getDatesFromQuery"

export function Promotion() {
  const dates = getDatesFromQuery()

  return (
    <section className={clsx(styles.section, styles.section3000_1)}>
      <Container className={styles.container}>
        <div className={clsx(styles.text, styles.textMargin3000_1)}>
          Совершите от 5 покупок* собственными или заемными средствами на общую сумму от 10 000
          руб.** три отчетных периода подряд (в Ваш текущий отчетный период – с {dates[0]} по{" "}
          {dates[1]}, и два следующих – с {dates[2]} по {dates[3]} и с {dates[4]} по {dates[5]}), и
          за каждый месяц выполнения условий мы подарим Вам 1000 баллов*** на ХАЛВУ!
        </div>
        <img src={img1} alt="img" className={clsx(styles.img, styles.imgMargin3000_1)} />
        <img src={img2} alt="img" className={clsx(styles.img, styles.imgMargin3000_1)} />
        <img src={img3} alt="img" className={clsx(styles.img, styles.imgMargin3000_1)} />
      </Container>
    </section>
  )
}
